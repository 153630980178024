
import { defineComponent, ref, watch } from "vue";

export default defineComponent( {
  name: "CaseType",
  props: {
    type: {}
  },
  setup(props) {
    const data = ref({name: '', clazz:''})
    const build = (type) =>{
      switch (type){
        case 'BG':
        case 'Baggage':
          data.value = {name: 'Baggage', clazz: 'primary'}
          break
        case 'IQ':
        case 'Inquiry' :
          data.value = {name: 'Inquiry', clazz: 'primary'}
          break
        case 'IP':
        case 'In-patient':
          data.value = {name: 'In-patient', clazz: 'primary'}
          break
        case 'OP':
        case 'Out-patient':
          data.value = {name: 'Out-patient', clazz: 'primary'}
          break
        case 'OT':
        case 'Other':
          data.value = {name: 'Other', clazz: 'primary'}
          break
      }
    }
    watch(()=>props.type, (cb)=>{
      build(cb)
    })
    build(props.type)
    return {
      data
    }
  }

})
